<template>
  <v-text-field
    v-model="dateValue"
    label="Belegdatum"
    placeholder="JJJJ-MM-TT"
    type="text"
    inputmode="numeric"
    v-mask="mask"
    prepend-icon="event"
    :rules="dateRules"
    class="mb-3"
  >
    <template v-slot:append>
      <v-menu
        v-model="invoiceDatePicker"
        :close-on-content-click="false"
        :nudge-left="300"
        transition="scale-transition"
        offset-y
        origin="top right 0"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-on="on" v-bind="attrs">
            event_available
          </v-icon>
        </template>
        <v-date-picker
          v-model="dateValue"
          @input="invoiceDatePicker = false"
        ></v-date-picker>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  name: "DateTextField",
  directives: { mask },
  props: {
    value: {
      type: String
    }
  },
  data() {
    return {
      internalValue: "",
      mask: "####-##-##",
      dateRules: [
        v => !!v || "Bitte Datum angeben",
        v => !!this.parseDate(v) || "ungültiges Datum"
      ],
      invoiceDatePicker: false
    };
  },
  computed: {
    dateValue: {
      get() {
        return this.internalValue;
      },
      set(val) {
        this.internalValue = val;
        this.$emit("input", val);
      }
    }
  },
  watch: {
    value: function(val) {
      this.dateValue = val;
    }
  },
  mounted() {
    this.dateValue = this.value;
  },
  methods: {
    parseDate(dateString) {
      let parts = dateString.split("-");
      let year = parseInt(parts[0], 10);
      let month = parseInt(parts[1], 10) - 1;
      let day = parseInt(parts[2], 10);
      let date = new Date(year, month, day);
      if (
        year !== date.getFullYear() ||
        month !== date.getMonth() ||
        day !== date.getDate()
      ) {
        return undefined;
      }
      return date;
    }
  }
};
</script>

<style scoped></style>
