<template>
  <v-lazy
    :options="{
      threshold: 0.5
    }"
    min-height="85"
    transition="fade-transition"
  >
    <v-list-item
      class="py-1"
      @click="
        $router.push({
          name: 'invoice-details',
          params: { invoiceId: invoice.id }
        })
      "
    >
      <v-row class="mx-0 justify-space-between">
        <div>
          <div class="font-weight-medium">
            Beleg #{{ getInvoiceReference() }}
          </div>
          <v-row class="mx-0 text--secondary text-body-2">
            <span class="pr-2">{{ formatDate(invoice.date) }}</span>
            {{ invoice.usage }}
          </v-row>
          <v-row class="mx-0 justify-start align-center">
            <div class="mr-1 my-1">
              <submitter-chip
                v-if="showSubmitter"
                :submitter="invoice.submitter"
                small
              />
            </div>
            <invoice-status small :status="invoice.status" class="mr-1 my-1" />
            <div class="mr-1 my-1" v-for="tagId in invoice.tags" :key="tagId">
              <v-chip small>
                {{ tagGetter(tagId).name }}
              </v-chip>
            </div>
          </v-row>
        </div>
        <v-row
          v-if="!isMobile"
          class="mx-0 justify-start justify-md-end align-center"
        >
          <receipt-copy-preview-with-overlay
            v-for="copy in invoice.receiptCopy"
            :key="copy"
            class="ml-1"
            :file-name="copy"
            :width="40"
            :height="60"
          >
            <v-btn icon @click.stop="openFileInNewTab(copy)">
              <v-icon small>
                open_in_new
              </v-icon>
            </v-btn>
          </receipt-copy-preview-with-overlay>
        </v-row>
      </v-row>
      <div style="width: 120px" class="text-right text-h6 ml-3">
        {{ invoice.amount }} €
      </div>
    </v-list-item>
  </v-lazy>
</template>

<script>
import InvoiceStatus from "@/components/invoices/InvoiceStatus";
import Parser from "@/utils/Parser";
import { mapGetters } from "vuex";
import SubmitterChip from "@/components/invoices/SubmitterChip";
import InvoiceAPI from "@/api/InvoiceAPI";
import ReceiptCopyPreviewWithOverlay from "@/components/preview/ReceiptCopyPreviewWithOverlay";
export default {
  name: "InvoiceListItem",
  components: { ReceiptCopyPreviewWithOverlay, SubmitterChip, InvoiceStatus },
  props: {
    invoice: Object,
    showSubmitter: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("invoices", { tagGetter: "tag" }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    formatDate(date) {
      return Parser.parseDate(new Date(date));
    },
    getInvoiceReference() {
      return (
        new Date(this.invoice.submissionDate).getFullYear() +
        "-" +
        this.invoice.id
      );
    },
    openFileInNewTab(filename) {
      window.open(InvoiceAPI.getReceiptCopyUrl(filename), "_blank");
    }
  }
};
</script>

<style scoped></style>
