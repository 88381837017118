<template>
  <div>
    <div>
      <v-icon class="mb-1">
        receipt
      </v-icon>
      <span style="font-size: 16px" class="ml-2">Belege:</span>
    </div>
    <v-row class="px-0 ml-1 ml-md-7 mb-3 justify-start">
      <receipt-copy-preview-with-overlay
        v-for="(receipt, partNr) in receiptCopyFiles"
        :key="receipt.name"
        :width="isMobile ? 180 : 200"
        :height="isMobile ? 250 : 300"
        :file="receipt"
        class="ma-1"
      >
        <v-btn icon @click="deleteReceiptCopyPart(partNr)">
          <v-icon>
            delete
          </v-icon>
        </v-btn>
        <v-btn icon @click="openFileInNewTab(receipt.name)">
          <v-icon>
            open_in_new
          </v-icon>
        </v-btn>
        <v-btn icon @click="downloadFile(receipt, partNr + 1)">
          <v-icon>
            get_app
          </v-icon>
        </v-btn>
        <v-btn icon @click="showFullscreen(partNr)">
          <v-icon>
            fullscreen
          </v-icon>
        </v-btn>
      </receipt-copy-preview-with-overlay>
      <v-card
        :width="isMobile ? 180 : 200"
        :height="isMobile ? 250 : 300"
        outlined
        class="ma-1"
      >
        <v-container fill-height>
          <v-row class="justify-center align-center">
            <v-btn
              :width="isMobile ? 160 : 190"
              height="60"
              text
              @click="$refs.fileInput.click()"
            >
              <span style="white-space: normal">
                <v-icon> add </v-icon><br />
                <span>Beleg hinzufügen</span>
              </span>
            </v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-row>
    <v-dialog
      fullscreen
      v-model="fullscreen"
      hide-overlay
      max-width="100%"
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-toolbar color="primary" class="sticky">
          <v-btn icon @click="fullscreen = false" color="white">
            <v-icon>
              close
            </v-icon>
          </v-btn>
          <v-spacer />
          <v-btn
            icon
            @click="deleteReceiptCopyPart(fullscreenFileIndex)"
            color="white"
          >
            <v-icon>
              delete
            </v-icon>
          </v-btn>
          <v-btn
            icon
            @click="
              downloadFile(
                receiptCopyFiles[fullscreenFileIndex],
                fullscreenFileIndex + 1
              )
            "
            color="white"
          >
            <v-icon>
              get_app
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="mx-0 justify-center">
          <receipt-copy-fullscreen-preview
            :height="800"
            :width="1200"
            class="mt-5"
            :file="receiptCopyFiles[fullscreenFileIndex]"
          />
        </v-row>
      </v-card>
    </v-dialog>
    <input
      type="file"
      ref="fileInput"
      multiple
      accept="image/*,application/pdf"
      style="display:none"
    />
  </div>
</template>

<script>
import InvoiceAPI from "@/api/InvoiceAPI";
import ReceiptCopyPreviewWithOverlay from "@/components/preview/ReceiptCopyPreviewWithOverlay";
import ReceiptCopyFullscreenPreview from "@/components/preview/ReceiptCopyFullscreenPreview";
export default {
  name: "InvoiceFileEditor",
  components: { ReceiptCopyFullscreenPreview, ReceiptCopyPreviewWithOverlay },
  props: {
    receiptCopy: {
      type: Array,
      default() {
        return [];
      }
    },
    invoiceId: Number
  },
  data() {
    return {
      receiptCopyFiles: [],
      addedReceiptCopyFiles: [],
      deletedReceiptCopyFileNames: [],
      fullscreen: false,
      fullscreenFileIndex: -1
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    getFiles() {
      return this.receiptCopyFiles;
    },
    getAddedFiles() {
      return this.addedReceiptCopyFiles;
    },
    getDeletedFileNames() {
      return this.deletedReceiptCopyFileNames;
    },
    deleteReceiptCopyPart(partNr) {
      let deletedPart = this.receiptCopyFiles.splice(partNr, 1);
      if (this.receiptCopy.includes(deletedPart[0].name)) {
        this.deletedReceiptCopyFileNames.push(deletedPart[0].name);
      }
    },
    async downloadFile(file, partNr) {
      let a = document.createElement("a");
      a.download =
        this.invoiceId > 0
          ? "Rechnung#" + this.invoiceId + "-" + partNr
          : file.name;
      a.href = window.URL.createObjectURL(file);
      a.click();
    },
    showFullscreen(fileIndex) {
      this.fullscreenFileIndex = fileIndex;
      this.fullscreen = true;
    },
    openFileInNewTab(filename) {
      window.open(InvoiceAPI.getReceiptCopyUrl(filename), "_blank");
    }
  },
  mounted() {
    this.$refs.fileInput.addEventListener(
      "change",
      () => {
        this.receiptCopyFiles.push(...this.$refs.fileInput.files);
        this.addedReceiptCopyFiles.push(...this.$refs.fileInput.files);
      },
      false
    );
    this.receiptCopy.forEach(fileName => {
      InvoiceAPI.getReceiptCopyFile(fileName)
        .then(file => {
          this.receiptCopyFiles.push(file);
        })
        .catch(error => {
          console.log(error);
        });
    });
  }
};
</script>

<style scoped></style>
