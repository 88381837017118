<template>
  <v-row class="mx-0 justify-center justify-lg-start" style="width: 100%">
    <global-events
      v-if="!isMobile"
      @keydown.ctrl.shift.x.prevent="$router.push('/invoices/submission')"
    />
    <v-card flat class="mt-md-3 mx-md-8 mx-lg-16" width="100%">
      <v-card-title class="justify-center justify-lg-start">
        <span>Kasse</span>
        <v-spacer />
        <v-tooltip bottom :disabled="isMobile">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              to="/invoices/submission"
              color="accent"
              :large="!isMobile"
            >
              <v-icon class="mr-1">
                receipt
              </v-icon>
              Neuen Beleg Einreichen
            </v-btn>
          </template>
          <span>Strg + Shift + X</span>
        </v-tooltip>
      </v-card-title>

      <div class="sticky-tabs">
        <v-tabs :grow="isMobile" v-model="tab">
          <v-tab
            ><v-badge :content="todos.length" :value="todos.length"
              >Todos</v-badge
            ></v-tab
          >
          <v-tab>Eigene</v-tab>
          <v-tab v-if="isAdmin || isStavo || isTreasurer">Stamm</v-tab>
          <v-tab v-if="isAdmin || isStavo || isTreasurer">Tags</v-tab>
        </v-tabs>
        <v-divider />
      </div>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <invoice-todos-overview class="pt-2" />
        </v-tab-item>
        <v-tab-item>
          <own-invoices-overview class="pt-2" />
        </v-tab-item>
        <v-tab-item v-if="isAdmin || isStavo || isTreasurer">
          <stammes-invoices-overview class="pt-2" />
        </v-tab-item>
        <v-tab-item v-if="isAdmin || isStavo || isTreasurer">
          <invoice-tag-tab class="pt-2" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-row>
</template>

<script>
import OwnInvoicesOverview from "@/components/invoices/OwnInvoicesOverview";
import { mapActions, mapGetters } from "vuex";
import GlobalEvents from "vue-global-events";
import StammesInvoicesOverview from "@/components/invoices/StammesInvoicesOverview";
import InvoiceTodosOverview from "@/components/invoices/InvoiceTodosOverview";
import InvoiceTagTab from "@/components/invoices/InvoiceTagTab";

export default {
  name: "InvoiceOverview",
  components: {
    InvoiceTagTab,
    InvoiceTodosOverview,
    StammesInvoicesOverview,
    OwnInvoicesOverview,
    GlobalEvents
  },
  data() {
    return {
      tab: null
    };
  },
  computed: {
    ...mapGetters("user", ["isAdmin", "isStavo", "isTreasurer"]),
    ...mapGetters("invoices", ["todos"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    ...mapActions("invoices", ["fetchInvoices", "fetchTags"]),
    ...mapActions("users", ["refreshUsers"])
  },
  mounted() {
    this.refreshUsers();
    this.fetchInvoices();
    this.fetchTags();
  }
};
</script>

<style>
.sticky-tabs {
  position: sticky;
  top: 3rem;
  z-index: 2;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
