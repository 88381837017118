<template>
  <div>
    <v-card :outlined="!isMobile" tile elevation="0">
      <v-expansion-panels accordion multiple :value="[0, 1]">
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h6 px-3"
            >Offene Belege</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-list>
              <v-list-item v-if="ownOutstandingInvoices.length === 0">
                <v-list-item-icon>
                  <v-icon>
                    receipt
                  </v-icon>
                </v-list-item-icon>
                <span class="font-weight-light"
                  >Momentan keine Belege eingereicht</span
                >
              </v-list-item>
              <div v-for="invoice in ownOutstandingInvoices" :key="invoice.id">
                <invoice-list-item :invoice="invoice" />
                <v-divider />
              </div>
              <div v-if="ownOutstandingInvoices.length > 1">
                <v-list-item>
                  <v-spacer />
                  <div class="text-overline">Insgesamt ausstehend:</div>
                  <div style="width: 120px" class="text-right text-h6 ml-3">
                    {{ formatMoney(this.ownOutstandingDebts) }} €
                  </div>
                </v-list-item>
              </div>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h6 px-3"
            >Abgeschlossene Belege</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-list>
              <v-list-item v-if="ownCompletedInvoices.length === 0">
                <v-list-item-icon>
                  <v-icon>
                    receipt
                  </v-icon>
                </v-list-item-icon>
                <span class="font-weight-light"
                  >Noch keine abgeschlossenen Belege</span
                >
              </v-list-item>
              <div v-for="invoice in ownCompletedInvoices" :key="invoice.id">
                <invoice-list-item :invoice="invoice" />
                <v-divider />
              </div>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import Parser from "@/utils/Parser";
import InvoiceListItem from "@/components/invoices/InvoiceListItem";
export default {
  name: "OwnInvoicesOverview",
  components: { InvoiceListItem },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    invoices() {
      return this.$store.state.invoices.invoices;
    },
    ownInvoices() {
      return this.invoices.filter(
        invoice => invoice.submitter === this.userInfo.id
      );
    },
    ownOutstandingInvoices() {
      return this.ownInvoices.filter(
        invoice => invoice.status === "Open" || invoice.status === "Approved"
      );
    },
    ownOutstandingDebts() {
      return this.ownOutstandingInvoices
        .map(invoice => this.parseMoneyString(invoice.amount))
        .reduce((acc, cur) => acc + cur);
    },
    ownCompletedInvoices() {
      return this.ownInvoices.filter(
        invoice =>
          invoice.status === "Rejected" ||
          invoice.status === "Paid" ||
          invoice.status.startsWith("Done")
      );
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    }
  },
  methods: {
    parseMoneyString(moneyString) {
      return Parser.stringToMoney(moneyString);
    },
    formatMoney(money) {
      return Parser.moneyToString(money);
    }
  }
};
</script>

<style scoped>
.v-expansion-panel-content__wrap {
  padding: 0;
}
.v-expansion-panel:before {
  box-shadow: none !important;
}
</style>
