export default {
  objectEquals(x, y) {
    if (x === null || x === undefined || y === null || y === undefined) {
      return x === y;
    }
    if (x === y || x.valueOf() === y.valueOf()) {
      return true;
    }
    if (Array.isArray(x) && x.length !== y.length) {
      return false;
    }
    if (!(x instanceof Object)) {
      return false;
    }
    if (!(y instanceof Object)) {
      return false;
    }
    let p = Object.keys(x);
    return (
      Object.keys(y).every(i => p.indexOf(i) !== -1) &&
      p.every(i => this.objectEquals(x[i], y[i]))
    );
  }
};
