<template>
  <v-container fill-height class="ma-0 pa-0">
    <v-row class="justify-center align-center ma-0 pa-0">
      <file-preview :file="loadedFile" :width="width" :height="height" />
    </v-row>
  </v-container>
</template>

<script>
import FilePreview from "@/components/preview/FilePreview";
import InvoiceAPI from "@/api/InvoiceAPI";
export default {
  name: "ReceiptCopyFullscreenPreview",
  components: { FilePreview },
  props: {
    fileName: String,
    width: Number,
    height: Number,
    file: File
  },
  data() {
    return {
      loadedFile: undefined
    };
  },
  watch: {
    fileName: function(newVal) {
      this.loadFile(newVal);
    },
    file: function() {
      this.loadedFile = this.file;
    }
  },
  mounted() {
    if (this.file) {
      this.loadedFile = this.file;
    } else {
      this.loadFile(this.fileName);
    }
  },
  methods: {
    loadFile(fileName) {
      InvoiceAPI.getReceiptCopyFile(fileName)
        .then(file => {
          this.loadedFile = file;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped></style>
