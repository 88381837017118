<template>
  <v-card
    outlined
    style="overflow: hidden"
    :width="width"
    :height="height"
    class="ml-1"
  >
    <v-container fill-height class="ma-0 pa-0">
      <v-row class="justify-center align-center ma-0 pa-0">
        <file-preview :file="loadedFile" :width="width - 2" />
      </v-row>
    </v-container>
    <slot></slot>
  </v-card>
</template>

<script>
import FilePreview from "@/components/preview/FilePreview";
import InvoiceAPI from "@/api/InvoiceAPI";
export default {
  name: "ReceiptCopyPreview",
  components: { FilePreview },
  props: {
    fileName: String,
    width: Number,
    height: Number,
    file: File
  },
  data() {
    return {
      loadedFile: undefined
    };
  },
  mounted() {
    if (this.file) {
      this.loadedFile = this.file;
    } else {
      InvoiceAPI.getReceiptCopyFile(this.fileName)
        .then(file => {
          this.loadedFile = file;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped></style>
