<template>
  <v-card>
    <v-card-title>
      Tag Verwaltung
      <v-spacer />
      <slot name="headerRight"></slot>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-text-field
        class="pr-3"
        v-model="newTag"
        prepend-icon="label"
        label="Neuen Tag erstellen"
        :append-icon="newTag ? 'add' : ''"
        @click:append="addNewTag()"
        @keydown.enter="addNewTag()"
      />
      <v-row class="ml-1 mr-3">
        <div class="mt-2 mb-n4 text-overline ">Tags:</div>
        <v-spacer />
        <v-text-field
          style="max-width: 200px"
          outlined
          hide-details
          dense
          v-model="search"
          prepend-inner-icon="search"
          label="Suchen"
        />
      </v-row>
      <v-list dense>
        <v-list-item v-if="tags.length < 1">
          <v-list-item-avatar>
            <v-icon>
              label
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            Keine Tags vorhanden
          </v-list-item-content>
        </v-list-item>
        <div v-for="(tag, index) in filteredTags" :key="tag.id">
          <v-list-item>
            <v-chip class="mr-1">
              {{ tag.name }}
            </v-chip>
            <v-spacer />
            <v-text-field
              style="max-width: 250px; min-width: 250px"
              ref="tagEditingFields"
              :value="tag.name"
              class="mt-0"
              v-show="editing.includes(tag.id)"
              @keydown.enter="endEditing(tag.id, index)"
            />
            <v-btn
              :disabled="!tag.active"
              icon
              @click="toggleEditing(tag.id, index)"
            >
              <v-icon>
                {{ editing.includes(tag.id) ? "save" : "edit" }}
              </v-icon>
            </v-btn>
            <v-btn
              :disabled="!tag.active"
              icon
              @click="showRemoveTagDialog(tag.id)"
            >
              <v-icon>
                delete
              </v-icon>
            </v-btn>
            <v-switch
              v-model="tag.active"
              class="ml-1"
              @change="updateTagActive($event, tag.id)"
            />
          </v-list-item>
          <v-divider />
        </div>
      </v-list>
    </v-card-text>
    <v-card-actions> </v-card-actions>
    <v-dialog width="350" v-model="removeTagDialog">
      <v-card>
        <v-card-title> {{ tagToRemove.name }} wirklich löschen? </v-card-title>
        <v-card-text>
          Wenn der Tag <b>{{ tagToRemove.name }}</b> gelöscht wird, wird dieser
          aus allen Belegen entfernt. Diese Aktion kann nicht rückgängig gemacht
          werden.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="removeTagDialog = false">
            Schließen
          </v-btn>
          <v-btn color="error" @click="removeTagDialogAction()">
            <v-icon>
              delete
            </v-icon>
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "InvoiceTagManager",
  data() {
    return {
      newTag: "",
      editing: [],
      removeTagDialog: false,
      tagToRemove: {},
      search: ""
    };
  },
  computed: {
    tags() {
      return this.$store.state.invoices.tags;
    },
    filteredTags() {
      if (!this.search) return this.tags;
      let trimmed = this.search.trim();
      return this.tags.filter(tag => tag.name.includes(trimmed));
    }
  },
  methods: {
    ...mapActions("invoices", [
      "fetchTags",
      "addTag",
      "updateTag",
      "removeTag"
    ]),
    addNewTag() {
      if (!this.newTag) return;
      this.addTag(this.newTag);
      this.newTag = "";
    },
    toggleEditing(id, index) {
      if (this.editing.includes(id)) {
        this.endEditing(id, index);
      } else {
        this.startEditing(id, index);
      }
    },
    startEditing(id, index) {
      this.editing.push(id);
      this.$nextTick(() => this.$refs.tagEditingFields[index].focus());
    },
    endEditing(id, textFieldIndex) {
      this.updateTagName(id, textFieldIndex);
      let index = this.editing.indexOf(id);
      this.editing.splice(index, 1);
    },
    showRemoveTagDialog(tagId) {
      this.tagToRemove = this.tags.find(tag => tag.id === tagId);
      this.removeTagDialog = true;
    },
    removeTagDialogAction() {
      this.removeTag(this.tagToRemove.id);
      this.removeTagDialog = false;
    },
    updateTagActive(active, tagId) {
      let updatedTag = JSON.parse(
        JSON.stringify(this.tags.find(tag => tag.id === tagId))
      );
      updatedTag.active = active || false;
      this.updateTag(updatedTag);
    },
    updateTagName(tagId, textFieldIndex) {
      let newName = this.$refs.tagEditingFields[
        textFieldIndex
      ].$el.querySelector("input").value;
      if (!newName) return;
      let updatedTag = JSON.parse(
        JSON.stringify(this.tags.find(tag => tag.id === tagId))
      );
      if (newName === updatedTag.name) return;
      updatedTag.name = newName || "";
      this.updateTag(updatedTag);
    }
  },
  mounted() {
    this.fetchTags();
  }
};
</script>

<style scoped></style>
