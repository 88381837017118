<template>
  <div class="mt-md-3 mx-md-8 mx-lg-16">
    <v-card flat>
      <v-card-title>
        Neuen Beleg Einreichen
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row class="mx-0">
          <div style="width: 600px" class="mr-md-12">
            <invoice-editor
              ref="invoiceEditor"
              :initial-invoice="{ submitter: userInfo.id, ...draftInvoice }"
            >
              <template v-slot:extension="{ invoice }">
                <div>
                  <v-row class="mx-0 align-center">
                    <div>
                      <v-icon>
                        account_balance_wallet
                      </v-icon>
                    </div>
                    <div class="ml-2">
                      <span style="font-size: 16px">Zielkonto:</span>
                    </div>
                  </v-row>
                  <bank-account-target
                    :user-id="invoice.submitter"
                    style="max-width: 568px"
                    class="ml-md-8 mt-3"
                  />
                </div>
              </template>
            </invoice-editor>
          </div>
          <invoice-file-editor ref="invoiceFileEditor" class="mt-6" />
        </v-row>
      </v-card-text>
      <v-divider class="mt-3" />
      <v-card-actions>
        <v-btn color="primary" elevation="0" @click="doInvoiceSubmission()">
          Einreichen
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InvoiceEditor from "@/components/invoices/InvoiceEditor";
import InvoiceFileEditor from "@/components/invoices/InvoiceFileEditor";
import BankAccountTarget from "@/components/invoices/BankAccountTarget";

export default {
  name: "InvoiceSubmission",
  components: { BankAccountTarget, InvoiceFileEditor, InvoiceEditor },
  data() {
    return {
      draftInvoice: {}
    };
  },
  methods: {
    ...mapActions("invoices", ["submitInvoice"]),
    doInvoiceSubmission() {
      if (this.$refs.invoiceEditor.validate()) {
        let newInvoice = this.$refs.invoiceEditor.getEditablePartOfInvoice();
        newInvoice.receiptCopy = this.$refs.invoiceFileEditor.getFiles();
        this.submitInvoice(newInvoice);
        this.$router.push("/invoices");
      } else {
        this.$vuetify.goTo(1, { duration: 650 });
      }
    }
  },
  computed: {
    ...mapGetters("user", ["isAdmin", "isStavo", "isTreasurer"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    }
  },
  created() {
    this.draftInvoice = this.$store.state.invoices.draft_invoice;
  }
};
</script>

<style scoped></style>
