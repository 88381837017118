<template>
  <image-preview
    v-if="fileIsOfType('image/png', 'image/jpeg')"
    :image="file"
    :height="height"
    :width="width"
  />
  <pdf-preview
    v-else-if="fileIsOfType('application/pdf')"
    :pdf="file"
    :height="height"
    :width="width"
  />
  <div v-else-if="file">
    {{ file.name }}
  </div>
</template>

<script>
import ImagePreview from "@/components/preview/ImagePreview";
import PdfPreview from "@/components/preview/PdfPreview";
export default {
  name: "FilePreview",
  components: { PdfPreview, ImagePreview },
  props: {
    file: File,
    width: Number,
    height: Number
  },
  methods: {
    fileIsOfType(...typesToCheck) {
      if (!this.file || !this.file.type) return false;
      return typesToCheck.find(type => {
        return this.file.type === type;
      });
    }
  }
};
</script>

<style scoped></style>
