<template>
  <v-chip :small="small">
    <avatar
      class="mr-2 ml-n1"
      :class="{ 'ml-n3': small, 'ml-n1': !small }"
      size="20"
      :user-id="submitter"
    />
    {{ user.forename + " " + user.surname }}
  </v-chip>
</template>

<script>
import { mapGetters } from "vuex";
import Avatar from "@/components/user/Avatar";

export default {
  name: "SubmitterChip",
  components: { Avatar },
  props: {
    submitter: Number,
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("users", { userGetter: "user" }),
    user() {
      return this.userGetter(this.submitter);
    }
  }
};
</script>

<style scoped></style>
