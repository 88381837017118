export default {
  statusToText(status) {
    switch (status) {
      case "Open":
        return "Offen";
      case "Rejected":
        return "Storniert";
      case "Approved":
        return "Angenommen";
      case "Paid":
        return "Bezahlt";
      case "Done":
        return "Abgeschlossen";
      case "Done-Physical-Receipt":
        return "Abgeschlossen (P)";
      case "Done-Digital-Receipt":
        return "Abgeschlossen (D)";
      case "Done-No-Receipt":
        return "Abgeschlossen (N)";
    }
  },
  statusToColor(status) {
    switch (status) {
      case "Open":
        return "#90CAF9";
      case "Rejected":
        return "#F44336";
      case "Approved":
        return "#B9F6CA";
      case "Paid":
        return "#80CBC4";
      case "Done":
        return "#00E676";
      case "Done-Physical-Receipt":
        return "#00E676";
      case "Done-Digital-Receipt":
        return "#38ddff";
      case "Done-No-Receipt":
        return "#c796ff";
    }
  },
  statusToTooltip(status) {
    switch (status) {
      case "Open":
        return "Beleg eingereicht";
      case "Rejected":
        return "Beleg storniert";
      case "Approved":
        return "Beleg angenommen";
      case "Paid":
        return "Beleg beglichen";
      case "Done":
        return "Abgeschlossen";
      case "Done-Physical-Receipt":
        return "Physikalischen Original Beleg abgelegt";
      case "Done-Digital-Receipt":
        return "Original Beleg ist digital";
      case "Done-No-Receipt":
        return "kein zugehöriger Beleg";
    }
  }
};
