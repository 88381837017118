<template>
  <div class="mt-md-3 mx-md-8 mx-lg-16">
    <global-events
      v-if="!isMobile"
      @keydown.ctrl.e.prevent="startEditing()"
      @keyup.esc="endEditing()"
      @keydown.ctrl.s.prevent="saveAndEndEditing()"
    />
    <v-card flat>
      <v-card-title>
        <span class="mr-1 my-1">Beleg #{{ getInvoiceReference() }}</span>
        <v-row class="mx-0 my-1" style="flex-grow: 1">
          <div class="mr-1">
            <v-chip v-if="ownInvoice">
              Meine Belege
            </v-chip>
          </div>
          <invoice-status :status="invoice.status" />
        </v-row>
        <v-btn
          text
          v-if="isAdmin || isStavo || isTreasurer"
          @click="auditLogDialog = true"
          :large="!isMobile"
          class="mr-1"
        >
          Audit-Log
        </v-btn>
        <v-tooltip bottom :disabled="isMobile">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="accent"
              :large="!isMobile"
              @click="toggleEditing()"
              class="my-1"
            >
              <v-icon class="mr-1">
                {{ editing ? "save" : "edit" }}
              </v-icon>
              <span>{{ editing ? "Speichern" : "Beleg Anpassen" }}</span>
            </v-btn>
          </template>
          <span>{{ editing ? "Strg + S" : "Strg + E" }}</span>
        </v-tooltip>
      </v-card-title>
      <v-divider />
      <div>
        <invoice-details
          v-if="!editing"
          :invoice-id="invoice.id"
          :own-invoice="ownInvoice"
        />
        <div v-else>
          <v-card-text>
            <v-row class="mx-0">
              <invoice-editor
                style="width: 600px;"
                class="mr-md-6"
                ref="invoiceEditor"
                :initial-invoice="invoice"
              />
              <invoice-file-editor
                :invoice-id="invoice.id"
                ref="invoiceFileEditor"
                :receipt-copy="invoice.receiptCopy"
              />
            </v-row>
          </v-card-text>
        </div>
      </div>
    </v-card>
    <v-dialog
      :fullscreen="isMobile"
      width="800"
      v-model="auditLogDialog"
      scrollable
    >
      <v-card tile style="overflow: auto">
        <div class="sticky">
          <v-toolbar flat>
            <span class="text-h6"
              >Audit Log (Beleg #{{ getInvoiceReference() }})</span
            >
            <v-spacer />
            <v-btn icon @click="auditLogDialog = false">
              <v-icon>
                close
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider />
        </div>
        <invoice-audit-log :invoice-id="invoice.id" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InvoiceStatus from "@/components/invoices/InvoiceStatus";
import InvoiceDetails from "@/components/invoices/InvoiceDetails";
import InvoiceEditor from "@/components/invoices/InvoiceEditor";
import InvoiceFileEditor from "@/components/invoices/InvoiceFileEditor";
import GlobalEvents from "vue-global-events";
import InvoiceAuditLog from "@/components/invoices/InvoiceAuditLog";
import Equal from "@/utils/Equal";

export default {
  name: "InvoiceDetailView",
  components: {
    InvoiceAuditLog,
    InvoiceFileEditor,
    InvoiceEditor,
    InvoiceDetails,
    InvoiceStatus,
    GlobalEvents
  },
  data() {
    return {
      lastValueAuditLog: this.$route.query.auditLog === "true"
    };
  },
  computed: {
    ...mapGetters("user", ["isAdmin", "isStavo", "isTreasurer"]),
    ...mapGetters("invoices", { invoiceGetter: "invoice", tagGetter: "tag" }),
    invoice() {
      return this.invoiceGetter(parseInt(this.$route.params.invoiceId));
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    ownInvoice() {
      return this.invoice.submitter === this.userInfo.id;
    },
    editing() {
      return this.$route.query.editing;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    auditLogDialog: {
      get() {
        return this.$route.query.auditLog === "true";
      },
      set(val) {
        // Comparing the new val with the last value and only changing the route on a new value,
        // prevents navigation cancellation errors due to pushing the same route multiple times.
        // This case can happen when the dialog is closed via esc key or click-outside.
        if (val !== this.lastValueAuditLog) {
          this.lastValueAuditLog = val;
          this.$router.push({ query: { auditLog: val } });
        }
      }
    }
  },
  methods: {
    ...mapActions("invoices", [
      "updateInvoice",
      "addInvoiceReceiptCopyParts",
      "deleteInvoiceReceiptCopyParts"
    ]),
    toggleEditing() {
      if (this.editing) {
        this.saveAndEndEditing();
      } else {
        this.startEditing();
      }
    },
    startEditing() {
      if (!this.editing) {
        this.$router.replace({ query: { editing: "true" } });
      }
    },
    endEditing() {
      if (this.editing) {
        this.$router.replace({ query: {} });
      }
    },
    saveAndEndEditing() {
      this.saveChanges();
      this.endEditing();
    },
    async saveChanges() {
      let editablePartOfInvoice = this.$refs.invoiceEditor.getEditablePartOfInvoice();
      let updatedInvoice = { ...this.invoice, ...editablePartOfInvoice };
      let addPayload = {
        invoiceId: this.invoice.id,
        receiptCopyParts: this.$refs.invoiceFileEditor.getAddedFiles()
      };
      let deletePayload = {
        invoiceId: this.invoice.id,
        receiptCopyParts: this.$refs.invoiceFileEditor.getDeletedFileNames()
      };
      console.log(this.invoice);
      console.log(updatedInvoice);
      if (!Equal.objectEquals(this.invoice, updatedInvoice)) {
        await this.updateInvoice(updatedInvoice);
      }
      if (addPayload.receiptCopyParts.length > 0) {
        await this.addInvoiceReceiptCopyParts(addPayload);
      }
      if (deletePayload.receiptCopyParts.length > 0) {
        await this.deleteInvoiceReceiptCopyParts(deletePayload);
      }
    },
    getInvoiceReference() {
      return (
        new Date(this.invoice.submissionDate).getFullYear() +
        "-" +
        this.invoice.id
      );
    }
  }
};
</script>

<style scoped>
.sticky {
  position: sticky;
  top: 0;
  z-index: 10;
}
</style>
