<template>
  <v-img :src="src" :max-width="width" :max-height="height" contain />
</template>

<script>
export default {
  name: "ImagePreview",
  props: {
    image: File,
    width: Number,
    height: Number
  },
  data() {
    return {
      src: ""
    };
  },
  watch: {
    image: function() {
      this.loadSrc();
    }
  },
  methods: {
    loadSrc() {
      let reader = new FileReader();
      reader.onload = e => {
        this.src = e.target.result;
      };
      reader.readAsDataURL(this.image);
    }
  },
  mounted() {
    this.loadSrc();
  }
};
</script>

<style scoped></style>
