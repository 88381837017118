<template>
  <div class="pl-3">
    <v-timeline dense align-top>
      <v-timeline-item small>
        <template slot="icon">
          <invoice-status
            status="Open"
            :tooltip="false"
            :disabled="invoice.status !== 'Open'"
          />
        </template>
        <div
          v-if="status === 'Open'"
          :style="{ 'background-color': statusToColor() }"
          style="height: 32px; width: 45px; position: relative; left: -30px; top: -4px; margin-bottom: -36px"
        ></div>
        <v-card
          flat
          :color="status === 'Open' ? statusToColor() : ''"
          class="mt-n1 ml-2 mx-0 pb-1 pt-1 pl-2 pr-2"
          width="292"
          min-height="32"
        >
          <div
            class="font-italic"
            :class="{
              'text-primary': 'Open' === status,
              'text--disabled': statusGreater('Open', status),
              'text-secondary': statusLess('Open', status)
            }"
          >
            Beleg eingereicht
            <v-icon small v-if="statusLessOrEqu('Open', status)">
              check
            </v-icon>
          </div>
          <v-expand-transition>
            <div v-if="hasTreasurerRights && invoice.status === 'Open'">
              <v-row class="mx-0 justify-start mt-2">
                <span>Eingereichten Beleg annehmen?</span>
              </v-row>
              <v-row class="mx-0 justify-start">
                <v-btn
                  color="#B9F6CA"
                  class="mr-4 my-1"
                  width="130"
                  @click="accept()"
                >
                  Annehmen
                </v-btn>
                <v-btn
                  color="#F44336"
                  class="my-1"
                  width="130"
                  @click="showRejectConfirmDialog()"
                >
                  Stornieren
                </v-btn>
              </v-row>
            </div>
          </v-expand-transition>
        </v-card>
      </v-timeline-item>
      <v-timeline-item small v-if="status !== 'Rejected'">
        <template slot="icon">
          <invoice-status
            status="Approved"
            :tooltip="false"
            :disabled="invoice.status !== 'Approved'"
          />
        </template>
        <div
          v-if="status === 'Approved'"
          :style="{ 'background-color': statusToColor() }"
          style="height: 32px; width: 45px; position: relative; left: -30px; top: -4px; margin-bottom: -36px"
        ></div>
        <v-card
          flat
          :color="status === 'Approved' ? statusToColor() : ''"
          class="mt-n1 ml-2 mx-0 pt-1 pl-2 pr-2 pb-2"
          min-height="32"
        >
          <div
            class="font-italic mb-n2"
            :class="{
              'text-primary': 'Approved' === status,
              'text--disabled': statusGreater('Approved', status),
              'text-secondary': statusLess('Approved', status)
            }"
          >
            Beleg angenommen
            <v-icon small v-if="statusLessOrEqu('Approved', status)">
              check
            </v-icon>
          </div>
          <v-expand-transition>
            <div v-if="hasTreasurerRights && invoice.status === 'Approved'">
              <div class="mt-3 text--secondary">Überweisungsdetails:</div>
              <invoice-payment-instruction-card
                :user-id="invoice.submitter"
                :usage="getUsageDescription()"
                :amount="parseMoneyString(invoice.amount)"
              />
              <v-btn depressed class="mr-4 mt-2" @click="paid()">
                Überwiesen
              </v-btn>
            </div>
          </v-expand-transition>
        </v-card>
      </v-timeline-item>
      <v-timeline-item small v-if="status === 'Rejected'" :tooltip="false">
        <template slot="icon">
          <invoice-status status="Rejected" />
        </template>
        <div
          v-if="status === 'Rejected'"
          :style="{ 'background-color': statusToColor() }"
          style="height: 32px; width: 45px; position: relative; left: -30px; top: -4px; margin-bottom: -36px"
        ></div>
        <v-card
          flat
          :color="status === 'Rejected' ? statusToColor() : ''"
          class="mt-n1 ml-2 mx-0 pt-1 pl-2 pr-2"
          min-height="32"
          width="150"
        >
          <div
            class="font-italic"
            :class="{
              'text--primary': 'Rejected' === status,
              'text--disabled': statusGreater('Rejected', status),
              'text--secondary': statusLess('Rejected', status)
            }"
          >
            Beleg storniert
            <v-icon small>
              clear
            </v-icon>
          </div>
          <v-row
            class="justify-start mx-0 pt-1 pb-2"
            v-if="invoice.statusMessage"
          >
            <v-card
              color="tertiary"
              flat
              rounded
              outlined
              style="font-size: 14px"
              width="360"
            >
              <div class="pa-1">
                <div class="font-weight-light">Status Nachricht:</div>
                <div>{{ invoice.statusMessage }}</div>
              </div>
            </v-card>
          </v-row>
        </v-card>
      </v-timeline-item>
      <v-timeline-item small v-if="status !== 'Rejected'">
        <template slot="icon">
          <invoice-status
            status="Paid"
            :tooltip="false"
            :disabled="invoice.status !== 'Paid'"
          />
        </template>
        <div
          v-if="status === 'Paid'"
          :style="{ 'background-color': statusToColor() }"
          style="height: 32px; width: 45px; position: relative; left: -30px; top: -4px; margin-bottom: -36px"
        ></div>
        <v-card
          flat
          :color="status === 'Paid' ? statusToColor() : ''"
          class="mt-n1 ml-2 mx-0 pt-1 pl-2 pr-2 pb-2"
          width="292"
          min-height="32"
        >
          <div
            class="font-italic mb-n2"
            :class="{
              'text-primary': 'Paid' === status,
              'text--disabled': statusGreater('Paid', status),
              'text-secondary': statusLess('Paid', status)
            }"
          >
            Beleg beglichen
            <v-icon small v-if="statusLessOrEqu('Paid', status)">
              check
            </v-icon>
          </div>
          <v-expand-transition>
            <div v-if="hasTreasurerRights && invoice.status === 'Paid'">
              <v-btn
                block
                depressed
                elevation="0"
                class="mt-4"
                @click="donePhysicalReceipt()"
              >
                Original Beleg abgelegt
              </v-btn>
              <v-btn
                block
                depressed
                elevation="0"
                class="mt-2"
                @click="doneDigitalReceipt()"
              >
                Original Beleg ist digital
              </v-btn>
              <v-btn
                block
                depressed
                elevation="0"
                class="mt-2"
                @click="doneNoReceipt()"
              >
                kein Beleg vorhanden
              </v-btn>
            </div>
          </v-expand-transition>
        </v-card>
      </v-timeline-item>
      <v-timeline-item small v-if="status !== 'Rejected'">
        <template slot="icon">
          <invoice-status
            :status="status.startsWith('Done') ? status : 'Done'"
            :tooltip="false"
            :disabled="!status.startsWith('Done')"
          />
        </template>
        <div
          v-if="status.startsWith('Done')"
          :style="{ 'background-color': statusToColor() }"
          style="height: 32px; width: 45px; position: relative; left: -30px; top: -4px; margin-bottom: -36px"
        ></div>
        <v-card
          flat
          :color="status.startsWith('Done') ? statusToColor() : ''"
          class="mt-n1 ml-2 mx-0 pt-1 pl-2 pr-2"
          height="32"
          width="292"
        >
          <div
            :style="{
              'margin-left': status.startsWith('Done') ? '12px' : '0px'
            }"
            class="font-italic"
            :class="{
              'text-primary': status.startsWith('Done'),
              'text--disabled': statusGreater('Done', status),
              'text-secondary': statusLess('Done', status)
            }"
          >
            {{ statusToTooltip(status) }}
            <v-icon small v-if="statusLessOrEqu('Done', status)">
              check
            </v-icon>
          </div>
        </v-card>
      </v-timeline-item>
    </v-timeline>
    <v-btn
      v-if="hasTreasurerRights"
      class="ma-1"
      text
      @click="showManuelEditorDialog()"
    >
      Status Anpassen
    </v-btn>
    <v-dialog width="500" v-model="confirmDialog">
      <v-card>
        <v-card-title>
          Beleg Stornieren
        </v-card-title>
        <v-card-subtitle class="mt-n1">
          Über eine Status Nachricht kannst du den Grund für deine Entscheidung
          mitteilen.
        </v-card-subtitle>
        <v-card-text>
          <v-textarea
            v-model="newStatusMessage"
            outlined
            rows="2"
            hide-details
            label="Status Nachricht"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="confirmDialog = false" text>
            Abbrechen
          </v-btn>
          <v-btn color="error" @click="reject()">
            Stornieren
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="500" v-model="manuelDialog">
      <v-card>
        <v-card-title>
          Manueller Status Editor
        </v-card-title>
        <v-card-text>
          <v-select v-model="newStatus" :items="possibleStatus" label="Status">
            <template v-slot:item="{ item }">
              <invoice-status :tooltip="false" :status="item" />
            </template>
            <template v-slot:selection="{ item }">
              <invoice-status :tooltip="false" :status="item" />
            </template>
          </v-select>
          <v-textarea
            v-model="newStatusMessage"
            outlined
            rows="2"
            hide-details
            label="Status Nachricht"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="manuelDialog = false" text>
            Abbrechen
          </v-btn>
          <v-btn color="success" @click="saveManuelChanges()">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InvoiceStatus from "@/components/invoices/InvoiceStatus";
import { mapActions, mapGetters } from "vuex";
import InvoicePaymentInstructionCard from "@/components/invoices/InvoicePaymentInstructionCard";
import Parser from "@/utils/Parser";
import InvoiceStatusUtility from "@/utils/InvoiceStatusUtility";
export default {
  name: "InvoiceStatusOverview",
  components: { InvoicePaymentInstructionCard, InvoiceStatus },
  props: {
    invoiceId: Number
  },
  data() {
    return {
      confirmDialog: false,
      manuelDialog: false,
      newStatusMessage: "",
      newStatus: "",
      possibleStatus: [
        "Open",
        "Approved",
        "Rejected",
        "Paid",
        "Done-Physical-Receipt",
        "Done-Digital-Receipt",
        "Done-No-Receipt"
      ]
    };
  },
  computed: {
    ...mapGetters("invoices", { invoiceGetter: "invoice" }),
    ...mapGetters("user", ["isAdmin", "isStavo", "isTreasurer"]),
    hasTreasurerRights() {
      return this.isAdmin || this.isStavo || this.isTreasurer;
    },
    invoice() {
      return this.invoiceGetter(this.invoiceId);
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    status() {
      return this.invoice.status;
    }
  },
  methods: {
    statusToNumber(status) {
      switch (status) {
        case "Open":
          return 1;
        case "Approved":
          return 2;
        case "Rejected":
          return 2;
        case "Paid":
          return 3;
        case "Done":
        case "Done-Physical-Receipt":
        case "Done-Digital-Receipt":
        case "Done-No-Receipt":
          return 4;
      }
    },
    statusGreater(status1, status2) {
      return this.statusToNumber(status1) > this.statusToNumber(status2);
    },
    statusLess(status1, status2) {
      return this.statusToNumber(status1) < this.statusToNumber(status2);
    },
    statusLessOrEqu(status1, status2) {
      return this.statusToNumber(status1) <= this.statusToNumber(status2);
    },
    ...mapActions("invoices", ["updateInvoiceStatus"]),
    accept() {
      this.updateStatus("Approved", this.invoice.statusMessage);
    },
    showRejectConfirmDialog() {
      this.newStatusMessage = this.invoice.statusMessage;
      this.confirmDialog = true;
    },
    reject() {
      this.updateStatus("Rejected", this.newStatusMessage);
      this.confirmDialog = false;
    },
    paid() {
      this.updateStatus("Paid", this.invoice.statusMessage);
    },
    donePhysicalReceipt() {
      this.updateStatus("Done-Physical-Receipt", this.invoice.statusMessage);
    },
    doneDigitalReceipt() {
      this.updateStatus("Done-Digital-Receipt", this.invoice.statusMessage);
    },
    doneNoReceipt() {
      this.updateStatus("Done-No-Receipt", this.invoice.statusMessage);
    },
    showManuelEditorDialog() {
      this.newStatus = this.invoice.status;
      this.newStatusMessage = this.invoice.statusMessage;
      this.manuelDialog = true;
    },
    saveManuelChanges() {
      this.updateStatus(this.newStatus, this.newStatusMessage);
      this.manuelDialog = false;
    },
    updateStatus(status, statusMessage) {
      let payload = {
        invoiceId: this.invoiceId,
        status: status,
        statusMessage: statusMessage
      };
      this.updateInvoiceStatus(payload);
    },
    parseMoneyString(moneyString) {
      return Parser.stringToMoney(moneyString);
    },
    getUsageDescription() {
      return "Pfadfinder | #" + this.getInvoiceReference(this.invoice);
    },
    getInvoiceReference(invoice) {
      return new Date(invoice.submissionDate).getFullYear() + "-" + invoice.id;
    },
    statusToText() {
      return InvoiceStatusUtility.statusToText(this.status);
    },
    statusToColor() {
      return InvoiceStatusUtility.statusToColor(this.status);
    },
    statusToTooltip() {
      return InvoiceStatusUtility.statusToTooltip(this.status);
    }
  }
};
</script>

<style scoped></style>
