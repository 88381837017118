<template>
  <div>
    <v-form ref="invoiceForm" lazy-validation style="max-width: 600px">
      <v-text-field
        label="Verwendungszweck"
        prepend-icon="notes"
        v-model="invoice.usage"
        :rules="[v => !!v || 'Bitte Verwendungszweck angeben']"
        class="mb-1"
      />
      <v-text-field
        label="Betrag"
        prepend-icon="euro"
        v-money="moneyConfig"
        v-model="invoice.amount"
        inputmode="numeric"
        class="mb-1"
      />
      <date-text-field v-model="invoice.date" />
      <v-expand-transition>
        <v-autocomplete
          v-if="initialInvoice.submitter && (isAdmin || isStavo || isTreasurer)"
          v-model="invoice.submitter"
          :items="users"
          label="Einreicher"
          prepend-icon="person"
          chips
          item-text="name"
          item-value="id"
          :search-input.sync="submitterAutocompleteInput"
        >
          <template v-slot:selection="data">
            <submitter-chip :submitter="data.item.id" />
          </template>
          <template v-slot:item="data">
            <v-list-item-avatar>
              <avatar size="35" :user-id="data.item.id" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{
                data.item.forename + " " + data.item.surname
              }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-expand-transition>
      <v-autocomplete
        v-model="invoice.tags"
        :items="activeTags"
        chips
        label="Tags"
        multiple
        prepend-icon="label"
        item-text="name"
        item-value="id"
        :search-input.sync="tagAutocompleteInput"
      >
        <template v-slot:selection="data">
          <v-chip
            close
            @click:close="
              invoice.tags.splice(
                invoice.tags.indexOf(tag => tag.id === data.id)
              )
            "
          >
            {{ data.item.name }}
          </v-chip>
        </template>
        <template v-slot:no-data>
          <v-list-item
            dense
            @click="createTagForInvoiceSubmission(tagAutocompleteInput)"
          >
            <v-list-item-avatar>
              <v-icon>
                add
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <div>
                Neuen Tag
                <span class="font-weight-bold">{{ tagAutocompleteInput }}</span>
                erstellen
              </div>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
      <v-btn
        text
        class="ml-4 mb-5"
        @click="tagManagerDialog = true"
        v-if="isAdmin || isStavo || isTreasurer"
      >
        Tags verwalten
      </v-btn>
      <v-textarea
        outlined
        label="Kommentar"
        prepend-icon="comment"
        v-model="invoice.comment"
        rows="2"
        hint="Anmerkungen zu deinem Beleg bitte hier eintragen"
      />
      <slot name="extension" :invoice="invoice"></slot>
    </v-form>
    <v-dialog
      tile
      v-model="tagManagerDialog"
      width="600"
      :fullscreen="isMobile"
    >
      <invoice-tag-manager>
        <template v-slot:headerRight>
          <v-btn text @click="tagManagerDialog = false">
            Schließen
          </v-btn>
        </template>
      </invoice-tag-manager>
    </v-dialog>
  </div>
</template>

<script>
import InvoiceTagManager from "@/components/invoices/InvoiceTagManager";
import { VMoney } from "v-money";
import { mapActions, mapGetters, mapMutations } from "vuex";
import SubmitterChip from "@/components/invoices/SubmitterChip";
import Avatar from "@/components/user/Avatar";
import DateTextField from "@/components/utility/DateTextField";

export default {
  name: "InvoiceEditor",
  components: { DateTextField, Avatar, SubmitterChip, InvoiceTagManager },
  directives: { money: VMoney },
  props: {
    initialInvoice: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      invoice: {
        ...{
          usage: "",
          amount: 0,
          date: "",
          receiptCopy: [],
          comment: "",
          tags: [],
          submitter: 0
        },
        ...this.initialInvoice
      },
      moneyConfig: {
        decimal: ",",
        thousands: ".",
        suffix: " €",
        precision: 2
      },
      invoiceDatePicker: false,
      tagManagerDialog: false,
      tagAutocompleteInput: "",
      submitterAutocompleteInput: ""
    };
  },
  watch: {
    initialInvoice: function(newVal) {
      this.invoice = { ...this.invoice, ...newVal };
    }
  },
  methods: {
    ...mapActions("invoices", ["submitInvoice", "addTag"]),
    ...mapMutations("invoices", ["updateDraftInvoice"]),
    createTagForInvoiceSubmission(tagName) {
      if (!tagName) return;
      this.addTag(tagName);
    },
    validate() {
      return this.$refs.invoiceForm.validate();
    },
    getEditablePartOfInvoice() {
      this.cleanSelectedTags();
      this.invoice.amount = this.invoice.amount.replaceAll(" ", "");
      this.invoice.amount = this.invoice.amount.replaceAll("€", "");
      return this.invoice;
    },
    cleanSelectedTags() {
      this.invoice.tags = this.invoice.tags.filter(tag =>
        this.activeTags.map(activeTag => activeTag.id).includes(tag)
      );
    },
    updateDraftState() {
      if (!this.invoice.id) {
        //Only update in creation mode, not in edit mode
        this.updateDraftInvoice(this.getEditablePartOfInvoice());
      }
    }
  },
  computed: {
    ...mapGetters("user", ["isAdmin", "isTreasurer", "isStavo"]),
    ...mapGetters("invoices", ["activeTags"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    users() {
      return this.$store.state.users.users.map(user => {
        return { ...user, ...{ name: user.forename + " " + user.surname } };
      });
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    }
  },
  mounted() {
    window.addEventListener("beforeunload", this.updateDraftState);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.updateDraftState);
    this.updateDraftState();
  },
  beforeRouteLeave() {
    window.removeEventListener("beforeunload", this.updateDraftState);
    this.updateDraftState();
  }
};
</script>

<style scoped></style>
