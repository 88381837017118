<template>
  <div>
    <v-list-item v-if="todos.length === 0">
      <v-list-item-icon>
        <v-icon>
          check
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content class="font-weight-light">
        Alle Todos erledigt!
      </v-list-item-content>
    </v-list-item>
    <v-card :outlined="!isMobile" elevation="0" tile>
      <v-expansion-panels
        accordion
        class="elevation-0"
        multiple
        :value="[0, 1, 2, 3]"
      >
        <v-expansion-panel
          class="elevation-0"
          v-if="originalReceiptHandover.length > 0"
        >
          <v-expansion-panel-header class="text-h6 px-3"
            >Originalbelege Abgeben ({{
              originalReceiptHandover.length
            }})</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-list>
              <v-list-item>
                <v-list-item-content class="pb-0 mb-1">
                  <div class="text-body-2">
                    Notiere auf den jeweiligen Originalbelegen die zugehörige
                    Belegnummer und übergebe die Belege:
                  </div>
                  <v-row
                    class="mx-0 my-2 justify-center justify-md-start align-center"
                  >
                    <user-card
                      class="mr-3 my-1"
                      v-for="(treasurer, index) in treasurers"
                      :key="index"
                      :user-id="treasurer.id"
                      role-description="Kassier"
                      style="width: 340px"
                    />
                  </v-row>
                  <div class="text-overline">
                    Zu übergebene Belege:
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <div
                v-for="todo in originalReceiptHandover"
                :key="todo.invoiceId + todo.type"
              >
                <invoice-list-item :invoice="invoiceGetter(todo.invoiceId)" />
                <v-divider />
              </div>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          class="elevation-0"
          v-if="decisionsNeeded.length > 0"
        >
          <v-expansion-panel-header class="text-h6 px-3"
            >Neue Belege Überprüfen ({{
              decisionsNeeded.length
            }})</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <div
              v-for="todo in decisionsNeeded"
              :key="todo.invoiceId + todo.type"
            >
              <invoice-list-item
                :invoice="invoiceGetter(todo.invoiceId)"
                show-submitter
              />
              <v-divider />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="elevation-0" v-if="payments.size > 0">
          <v-expansion-panel-header class="text-h6 px-3">
            Überweisungen ({{ payments.size }})</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-divider />
            <div
              class="pt-1 pb-6"
              v-for="payment in payments"
              :key="'Payment' + payment[0]"
            >
              <invoice-payment :payment="payment[1]" :recipient="payment[0]" />
              <v-divider />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          class="elevation-0"
          v-if="storeOriginalReceipts.length > 0"
        >
          <v-expansion-panel-header class="text-h6 px-3">
            Originalbelege Ablegen ({{
              storeOriginalReceipts.length
            }})</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <div class="text-overline">
                    Für die folgenden Belege fehlt das Original:
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <div
                v-for="todo in storeOriginalReceipts"
                :key="todo.invoiceId + todo.type"
              >
                <invoice-list-item
                  :invoice="invoiceGetter(todo.invoiceId)"
                  show-submitter
                />
                <v-divider />
              </div>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InvoiceListItem from "@/components/invoices/InvoiceListItem";
import InvoicePayment from "@/components/invoices/InvoicePayment";
import UserCard from "@/components/user/UserCard";

export default {
  name: "InvoiceTodosOverview",
  components: { UserCard, InvoicePayment, InvoiceListItem },
  computed: {
    ...mapGetters("invoices", ["todos"]),
    ...mapGetters("invoices", { invoiceGetter: "invoice" }),
    ...mapGetters("users", ["treasurers"]),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    originalReceiptHandover() {
      return this.todos.filter(todo => todo.type === "OriginalReceiptHandover");
    },
    decisionsNeeded() {
      return this.todos.filter(todo => todo.type === "DecisionNeeded");
    },
    payments() {
      let singlePayments = this.todos.filter(todo => todo.type === "Payment");
      let payments = new Map();
      singlePayments.forEach(payment => {
        let submitter = this.invoiceGetter(payment.invoiceId).submitter;
        if (!payments.has(submitter)) {
          payments.set(submitter, [payment]);
        } else {
          let target = payments.get(submitter);
          target.push(payment);
          payments.set(submitter, target);
        }
      });
      return payments;
    },
    storeOriginalReceipts() {
      return this.todos.filter(todo => todo.type === "StoreOriginalReceipt");
    }
  }
};
</script>

<style scoped>
.v-expansion-panel:before {
  box-shadow: none !important;
}
</style>
