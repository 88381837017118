<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <receipt-copy-preview
        :width="width"
        :height="height"
        :file-name="fileName"
        :file="file"
      >
        <v-fade-transition>
          <v-overlay v-if="hover" absolute z-index="4">
            <v-row
              class="mx-0"
              :style="{ 'margin-bottom': height - 50 + 'px' }"
            >
              <slot></slot>
            </v-row>
          </v-overlay>
        </v-fade-transition>
      </receipt-copy-preview>
    </template>
  </v-hover>
</template>

<script>
import ReceiptCopyPreview from "@/components/preview/ReceiptCopyPreview";
export default {
  name: "ReceiptCopyPreviewWithOverlay",
  components: { ReceiptCopyPreview },
  props: {
    width: Number,
    height: Number,
    fileName: String,
    file: File
  }
};
</script>

<style scoped></style>
