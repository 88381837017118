<template>
  <div>
    <v-row class="mx-0 pt-1 pr-2 justify-end">
      <div class="text-right">
        <div style="font-size: 12px">
          <span class="font-weight-light">Einreichdatum:</span>
          {{ formatDateTime(invoice.submissionDate) }}
        </div>
        <div
          style="font-size: 12px"
          v-if="invoice.submissionDate !== invoice.lastUpdated"
        >
          <span class="font-weight-light">Letzte Änderung:</span>
          {{ formatDateTime(invoice.lastUpdated) }}
        </div>
      </div>
    </v-row>
    <v-card-text class="pt-0 mt-n3">
      <v-row>
        <div>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                euro
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="font-weight-light">Betrag:</div>
              <span class="text-h4">{{ invoice.amount }} €</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                notes
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="font-weight-light">Verwendungszweck:</div>
              {{ invoice.usage }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="!ownInvoice">
            <v-list-item-icon>
              <v-icon>
                person
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="font-weight-light">Eingereicht von:</div>
              <v-row class="mx-0 mt-1">
                <submitter-chip :submitter="invoice.submitter" />
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                event
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="font-weight-light">Belegdatum:</div>
              {{ formatDate(invoice.date) }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                label
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="font-weight-light">Tags:</div>
              <div v-if="invoice.tags.length === 0">kein Tag zugeordnet</div>
              <v-row class="mx-0 mt-1">
                <v-chip class="mr-1" v-for="tagId in invoice.tags" :key="tagId">
                  {{ tagGetter(tagId).name }}
                </v-chip>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="invoice.comment">
            <v-list-item-icon>
              <v-icon>
                comment
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="font-weight-light">Kommentar:</div>
              <div style="max-width: 300px; overflow-wrap: break-word">
                {{ invoice.comment }}
              </div>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                done_all
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="font-weight-light">Status:</div>
            </v-list-item-content>
          </v-list-item>
          <v-row class="mx-0 pl-md-10 pb-3">
            <invoice-status-overview :invoice-id="invoice.id" />
          </v-row>
        </div>
        <div>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                receipt
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="font-weight-light">Belege:</div>
              <div v-if="invoice.receiptCopy.length === 0">
                keine Belegkopien vorhanden
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-row class="px-0 ml-1 ml-md-14 mb-3 justify-start">
            <receipt-copy-preview-with-overlay
              v-for="(receipt, partNr) in invoice.receiptCopy"
              :key="receipt"
              :width="isMobile ? 180 : 200"
              :height="isMobile ? 250 : 300"
              :file-name="receipt"
              class="ma-1"
            >
              <v-btn icon @click="openFileInNewTab(receipt)">
                <v-icon>
                  open_in_new
                </v-icon>
              </v-btn>
              <v-btn icon @click="downloadFile(receipt, partNr + 1)">
                <v-icon>
                  get_app
                </v-icon>
              </v-btn>
              <v-btn icon @click="showFullscreen(partNr)">
                <v-icon>
                  fullscreen
                </v-icon>
              </v-btn>
            </receipt-copy-preview-with-overlay>
          </v-row>
        </div>
        <v-dialog
          fullscreen
          v-model="fullscreen"
          hide-overlay
          max-width="100%"
          transition="dialog-bottom-transition"
        >
          <v-card tile>
            <v-toolbar color="primary" class="sticky">
              <v-btn icon @click="fullscreen = false" color="white">
                <v-icon>
                  close
                </v-icon>
              </v-btn>
              <v-spacer />
              <v-btn
                icon
                @click="
                  downloadFile(
                    invoice.receiptCopy[fullscreenFileIndex],
                    fullscreenFileIndex + 1
                  )
                "
                color="white"
              >
                <v-icon>
                  get_app
                </v-icon>
              </v-btn>
            </v-toolbar>
            <v-row class="mx-0 justify-center">
              <receipt-copy-fullscreen-preview
                :height="800"
                :width="1200"
                class="mt-5"
                :file-name="invoice.receiptCopy[fullscreenFileIndex]"
              />
            </v-row>
          </v-card>
        </v-dialog>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Parser from "@/utils/Parser";
import ReceiptCopyPreviewWithOverlay from "@/components/preview/ReceiptCopyPreviewWithOverlay";
import InvoiceAPI from "@/api/InvoiceAPI";
import ReceiptCopyFullscreenPreview from "@/components/preview/ReceiptCopyFullscreenPreview";
import SubmitterChip from "@/components/invoices/SubmitterChip";
import InvoiceStatusOverview from "@/components/invoices/InvoiceStatusOverview";

export default {
  name: "InvoiceDetails",
  components: {
    InvoiceStatusOverview,
    SubmitterChip,
    ReceiptCopyFullscreenPreview,
    ReceiptCopyPreviewWithOverlay
  },
  props: {
    invoiceId: Number,
    ownInvoice: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      receiptCopies: [],
      fullscreen: false,
      fullscreenFileIndex: -1,
      statusExpand: true
    };
  },
  computed: {
    ...mapGetters("invoices", { invoiceGetter: "invoice", tagGetter: "tag" }),
    invoice() {
      return this.invoiceGetter(this.invoiceId);
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    formatDate(date) {
      return Parser.parseDate(new Date(date));
    },
    formatDateTime(datetime) {
      return Parser.parseDateTime(new Date(datetime));
    },
    async downloadFile(filename, partNr) {
      let file = await InvoiceAPI.getReceiptCopyFile(filename);
      let a = document.createElement("a");
      a.download = "Rechnung#" + this.invoice.id + "-" + partNr;
      a.href = window.URL.createObjectURL(file);
      a.click();
    },
    showFullscreen(fileIndex) {
      this.fullscreenFileIndex = fileIndex;
      this.fullscreen = true;
    },
    openFileInNewTab(filename) {
      window.open(InvoiceAPI.getReceiptCopyUrl(filename), "_blank");
    }
  }
};
</script>

<style scoped>
.sticky {
  position: sticky;
  top: 0;
  z-index: 10;
}
</style>
