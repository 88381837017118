<template>
  <div style="overflow: hidden">
    <v-list-item class="mb-n2">
      <v-list-item-content>
        <v-list-item-title class="mb-1">
          <div class="text-subtitle-2">
            Überweisung an <submitter-chip :submitter="recipient" small />
          </div>
        </v-list-item-title>
        <invoice-payment-instruction-card
          :user-id="recipient"
          :amount="totalPaymentAmount"
          :usage="getUsageDescription()"
        />
      </v-list-item-content>
    </v-list-item>
    <v-list-group sub-group no-action :value="false" class="ml-n6 pt-1">
      <template v-slot:activator>
        <v-list-item-content class="text-body-2">
          Zugehörige Belege:
        </v-list-item-content>
      </template>
      <div v-for="position in payment" :key="position.invoiceId">
        <invoice-list-item
          :invoice="invoiceGetter(position.invoiceId)"
          show-submitter
        />
        <v-divider />
      </div>
      <div>
        <v-list-item>
          <v-spacer />
          <div class="text-overline">Gesamtbetrag:</div>
          <div style="width: 120px" class="text-right text-h6 ml-3">
            {{ formatMoney(this.totalPaymentAmount) }} €
          </div>
        </v-list-item>
        <v-divider />
        <v-divider />
      </div>
    </v-list-group>
    <v-row class="mx-0 pa-2 justify-end">
      <v-btn depressed @click="paymentDone()">
        Überwiesen
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InvoiceListItem from "@/components/invoices/InvoiceListItem";
import Parser from "@/utils/Parser";
import SubmitterChip from "@/components/invoices/SubmitterChip";
import InvoicePaymentInstructionCard from "@/components/invoices/InvoicePaymentInstructionCard";

export default {
  name: "InvoicePayment",
  components: { InvoicePaymentInstructionCard, SubmitterChip, InvoiceListItem },
  props: {
    payment: Array,
    recipient: Number
  },
  computed: {
    ...mapGetters("invoices", { invoiceGetter: "invoice" }),
    totalPaymentAmount() {
      return this.payment
        .map(position => this.invoiceGetter(position.invoiceId))
        .map(invoice => this.parseMoneyString(invoice.amount))
        .reduce((acc, cur) => acc + cur);
    }
  },
  methods: {
    ...mapActions("invoices", ["updateInvoiceStatus"]),
    parseMoneyString(moneyString) {
      return Parser.stringToMoney(moneyString);
    },
    formatMoney(money) {
      return Parser.moneyToString(money);
    },
    getUsageDescription() {
      let description = "Pfadfinder: ";
      this.payment.forEach(position => {
        description +=
          "" +
          this.getInvoiceReference(this.invoiceGetter(position.invoiceId)) +
          ", ";
      });
      return description.slice(0, -2); //removes last ;
    },
    paymentDone() {
      this.payment.forEach(part => {
        let payload = {
          invoiceId: part.invoiceId,
          status: "Paid",
          statusMessage: this.invoiceGetter(part.invoiceId).statusMessage
        };
        this.updateInvoiceStatus(payload);
      });
    },
    getInvoiceReference(invoice) {
      return new Date(invoice.submissionDate).getFullYear() + "-" + invoice.id;
    }
  }
};
</script>

<style scoped></style>
