<template>
  <div>
    <v-card :outlined="!isMobile" elevation="0" tile>
      <v-expansion-panels accordion multiple :value="[0, 1]">
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h6 px-3"
            >Offene Belege</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-list>
              <v-list-item v-if="outstandingInvoices.length === 0">
                <v-list-item-icon>
                  <v-icon>
                    check
                  </v-icon>
                </v-list-item-icon>
                <span class="font-weight-light"
                  >Momentan keine offenen Belege</span
                >
              </v-list-item>
              <div v-for="invoice in outstandingInvoices" :key="invoice.id">
                <invoice-list-item :invoice="invoice" :show-submitter="true" />
                <v-divider />
              </div>
              <div v-if="outstandingInvoices.length > 1">
                <v-list-item>
                  <v-spacer />
                  <div class="text-overline">Insgesamt ausstehend:</div>
                  <div style="width: 120px" class="text-right text-h6 ml-3">
                    {{ formatMoney(this.outstandingDebts) }} €
                  </div>
                </v-list-item>
              </div>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h6 px-3"
            >Abgeschlossene Belege</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-list>
              <v-list-item v-if="completedInvoices.length === 0">
                <v-list-item-icon>
                  <v-icon>
                    receipt
                  </v-icon>
                </v-list-item-icon>
                <span class="font-weight-light"
                  >Noch keine abgeschlossenen Belege</span
                >
              </v-list-item>
              <div v-for="invoice in completedInvoices" :key="invoice.id">
                <invoice-list-item :invoice="invoice" :show-submitter="true" />
                <v-divider />
              </div>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import Parser from "@/utils/Parser";
import InvoiceListItem from "@/components/invoices/InvoiceListItem";
export default {
  name: "StammesInvoicesOverview",
  components: { InvoiceListItem },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    invoices() {
      return this.$store.state.invoices.invoices;
    },
    outstandingInvoices() {
      return this.invoices.filter(
        invoice => invoice.status === "Open" || invoice.status === "Approved"
      );
    },
    outstandingDebts() {
      return this.outstandingInvoices
        .map(invoice => this.parseMoneyString(invoice.amount))
        .reduce((acc, cur) => acc + cur);
    },
    completedInvoices() {
      return this.invoices.filter(
        invoice =>
          invoice.status === "Rejected" ||
          invoice.status === "Paid" ||
          invoice.status.startsWith("Done")
      );
    }
  },
  methods: {
    parseMoneyString(moneyString) {
      return Parser.stringToMoney(moneyString);
    },
    formatMoney(money) {
      return Parser.moneyToString(money);
    }
  }
};
</script>

<style scoped>
.v-expansion-panel:before {
  box-shadow: none !important;
}
</style>
