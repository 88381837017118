<template>
  <v-card
    flat
    rounded
    :color="paymentInstructionData.length == 0 ? null : 'tertiary'"
    min-height="160"
    :loading="paymentInstructionData.length == 0"
  >
    <v-card-text class="pa-0">
      <v-list-item
        dense
        v-for="instructionPart in paymentInstructionData"
        :key="instructionPart.subject"
        class="my-0"
        @click="copyToClipboard(instructionPart.value, instructionPart.subject)"
      >
        <v-list-item-content>
          <v-row class="mx-0">
            <div class="font-weight-light" style="width: 150px">
              {{ instructionPart.subject }}:
            </div>
            <div>
              {{ instructionPart.value }} {{ instructionPart.unit || "" }}
            </div>
          </v-row>
        </v-list-item-content>
      </v-list-item>
      <v-list-item dense class="my-0">
        <v-list-item-content v-if="qr_code !== null">
          <v-row class="mx-0">
            <div class="font-weight-light">
              <img :src="qr_code.toDataURL()" />
            </div>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
  </v-card>
</template>

<script>
import Clipboard from "@/utils/Clipboard";
import UsersAPI from "@/api/UsersAPI";
import Parser from "@/utils/Parser";
import { mapActions } from "vuex";
import QRious from "qrious";
import generateQrCode from "sepa-payment-qr-code";

export default {
  name: "InvoicePaymentInstructionCard",
  props: {
    userId: Number,
    amount: Number,
    usage: String
  },
  data() {
    return {
      bankAccount: {},
      paymentInstructionData: [],
      qr_code: null
    };
  },
  methods: {
    ...mapActions("snackbar", ["showSnackbar"]),
    async getBankAccount() {
      await UsersAPI.getBankAccount(this.userId)
        .then(async bankAccount => {
          if (bankAccount !== "no saved bank account") {
            this.bankAccount = bankAccount;
          } else {
            await UsersAPI.getFinancialData(this.userId).then(financialData => {
              this.bankAccount = financialData;
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    constructPaymentInstructionData() {
      let epcdata = {
        name: this.bankAccount.AccountHolder, // Name
        iban: this.bankAccount.IBAN, // IBAN
        amount: this.amount / 100, // Amount (Format „#.##“)
        unstructuredReference: this.usage // Verwendungszweck (max of 140 characters)
      };
      if (this.bankAccount.BIC && this.bankAccount.BIC !== "") {
        epcdata["bic"] = this.bankAccount.BIC;
      }

      if (epcdata["amount"] >= 0.01 && epcdata["name"] && epcdata["name"] != "") {
        const qrdata = generateQrCode(epcdata);
        const qr = new QRious({
          value: qrdata
        });
        this.qr_code = qr;
      }
      let instructions = [];
      instructions.push({
        subject: "Empfänger",
        value: this.bankAccount.AccountHolder
      });
      instructions.push({ subject: "IBAN", value: this.bankAccount.IBAN });
      instructions.push({
        subject: "Betrag",
        value: this.formatMoney(this.amount),
        unit: "€"
      });
      instructions.push({
        subject: "Verwendungszweck",
        value: this.usage
      });
      this.paymentInstructionData = instructions;
    },
    formatMoney(money) {
      return Parser.moneyToString(money);
    },
    copyToClipboard(text, subject) {
      Clipboard.copyTextToClipboard(text).then(() => {
        this.showSnackbar({
          text: subject + " kopiert"
        });
      });
    }
  },
  async mounted() {
    await this.getBankAccount();
    this.constructPaymentInstructionData();
  }
};
</script>

<style scoped></style>
