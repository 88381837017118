<template>
  <div>
    <pdfview
      :src="src"
      :style="{ maxWidth: width + 'px', maxHeight: height + 'px' }"
    />
  </div>
</template>

<script>
import pdfview from "vue-pdf";
export default {
  name: "PdfPreview",
  components: {
    pdfview: pdfview
  },
  props: {
    pdf: File,
    width: Number,
    height: Number
  },
  data() {
    return {
      src: ""
    };
  },
  watch: {
    pdf: function() {
      this.loadSrc();
    }
  },
  methods: {
    loadSrc() {
      let reader = new FileReader();
      reader.onload = e => {
        this.src = e.target.result;
      };
      reader.readAsDataURL(this.pdf);
    }
  },
  mounted() {
    this.loadSrc();
  }
};
</script>

<style scoped></style>
