<template>
  <div>
    <v-card flat rounded color="tertiary" v-if="bankAccountValid">
      <v-card-text>
        <v-row class="mx-0">
          <div class="font-weight-light" style="width: 150px">
            Kontoinhaber:
          </div>
          <div>{{ bankAccount.AccountHolder }}</div>
        </v-row>
        <v-row class="mx-0">
          <div class="font-weight-light" style="width: 150px">
            IBAN:
          </div>
          <div>{{ bankAccount.IBAN }}</div>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat rounded color="tertiary" v-else-if="namiBankAccountValid">
      <v-card-text>
        <div class="font-weight-medium">Beitragskonto</div>
        <v-row class="mx-0">
          <div class="font-weight-light" style="width: 150px">
            Kontoinhaber:
          </div>
          <div>{{ namiBankAccount.AccountHolder }}</div>
        </v-row>
        <v-row class="mx-0">
          <div class="font-weight-light" style="width: 150px">
            IBAN:
          </div>
          <div>{{ namiBankAccount.IBAN }}</div>
        </v-row>
      </v-card-text>
    </v-card>
    <div v-else>
      <div class="font-italic my-3 px-3">kein Konto vorhanden</div>
    </div>
    <div class="mt-1 pl-1" v-if="userId === userInfo.id">
      {{
        bankAccountValid
          ? "Überweisungskonto ändern"
          : "Konto für Überweisungen hinterlegen"
      }}
      <a @click="$router.push('/personal')">&rarr; Persönlicher Bereich</a>
    </div>
  </div>
</template>

<script>
import UsersAPI from "@/api/UsersAPI";

export default {
  name: "BankAccountTarget",
  props: {
    userId: Number
  },
  data() {
    return {
      namiBankAccount: {
        IBAN: "",
        AccountHolder: ""
      },
      bankAccount: {
        IBAN: "",
        AccountHolder: ""
      }
    };
  },
  watch: {
    userId() {
      this.bankAccount = {
        IBAN: "",
        AccountHolder: ""
      };
      this.namiBankAccount = {
        IBAN: "",
        AccountHolder: ""
      };
      this.getBankAcount();
      this.getFinancialData();
    }
  },
  computed: {
    namiBankAccountValid() {
      return this.validateBankAccount(this.namiBankAccount);
    },
    bankAccountValid() {
      return this.validateBankAccount(this.bankAccount);
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    }
  },
  methods: {
    async getFinancialData() {
      await UsersAPI.getFinancialData(this.userId)
        .then(financialData => {
          this.namiBankAccount = financialData;
        })
        .catch(error => {
          console.log(error);
        });
    },
    async getBankAcount() {
      await UsersAPI.getBankAccount(this.userId)
        .then(bankAccount => {
          if (bankAccount !== "no saved bank account") {
            this.bankAccount = bankAccount;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    validateBankAccount(bankAccount) {
      return !!(
        bankAccount &&
        bankAccount.IBAN &&
        bankAccount.IBAN !== "" &&
        bankAccount.AccountHolder &&
        bankAccount.AccountHolder !== ""
      );
    }
  },
  async mounted() {
    await this.getBankAcount();
    await this.getFinancialData();
  }
};
</script>

<style scoped></style>
