var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-3"},[_c('v-timeline',{attrs:{"dense":"","align-top":""}},[_c('v-timeline-item',{attrs:{"small":""}},[_c('template',{slot:"icon"},[_c('invoice-status',{attrs:{"status":"Open","tooltip":false,"disabled":_vm.invoice.status !== 'Open'}})],1),(_vm.status === 'Open')?_c('div',{staticStyle:{"height":"32px","width":"45px","position":"relative","left":"-30px","top":"-4px","margin-bottom":"-36px"},style:({ 'background-color': _vm.statusToColor() })}):_vm._e(),_c('v-card',{staticClass:"mt-n1 ml-2 mx-0 pb-1 pt-1 pl-2 pr-2",attrs:{"flat":"","color":_vm.status === 'Open' ? _vm.statusToColor() : '',"width":"292","min-height":"32"}},[_c('div',{staticClass:"font-italic",class:{
            'text-primary': 'Open' === _vm.status,
            'text--disabled': _vm.statusGreater('Open', _vm.status),
            'text-secondary': _vm.statusLess('Open', _vm.status)
          }},[_vm._v(" Beleg eingereicht "),(_vm.statusLessOrEqu('Open', _vm.status))?_c('v-icon',{attrs:{"small":""}},[_vm._v(" check ")]):_vm._e()],1),_c('v-expand-transition',[(_vm.hasTreasurerRights && _vm.invoice.status === 'Open')?_c('div',[_c('v-row',{staticClass:"mx-0 justify-start mt-2"},[_c('span',[_vm._v("Eingereichten Beleg annehmen?")])]),_c('v-row',{staticClass:"mx-0 justify-start"},[_c('v-btn',{staticClass:"mr-4 my-1",attrs:{"color":"#B9F6CA","width":"130"},on:{"click":function($event){return _vm.accept()}}},[_vm._v(" Annehmen ")]),_c('v-btn',{staticClass:"my-1",attrs:{"color":"#F44336","width":"130"},on:{"click":function($event){return _vm.showRejectConfirmDialog()}}},[_vm._v(" Stornieren ")])],1)],1):_vm._e()])],1)],2),(_vm.status !== 'Rejected')?_c('v-timeline-item',{attrs:{"small":""}},[_c('template',{slot:"icon"},[_c('invoice-status',{attrs:{"status":"Approved","tooltip":false,"disabled":_vm.invoice.status !== 'Approved'}})],1),(_vm.status === 'Approved')?_c('div',{staticStyle:{"height":"32px","width":"45px","position":"relative","left":"-30px","top":"-4px","margin-bottom":"-36px"},style:({ 'background-color': _vm.statusToColor() })}):_vm._e(),_c('v-card',{staticClass:"mt-n1 ml-2 mx-0 pt-1 pl-2 pr-2 pb-2",attrs:{"flat":"","color":_vm.status === 'Approved' ? _vm.statusToColor() : '',"min-height":"32"}},[_c('div',{staticClass:"font-italic mb-n2",class:{
            'text-primary': 'Approved' === _vm.status,
            'text--disabled': _vm.statusGreater('Approved', _vm.status),
            'text-secondary': _vm.statusLess('Approved', _vm.status)
          }},[_vm._v(" Beleg angenommen "),(_vm.statusLessOrEqu('Approved', _vm.status))?_c('v-icon',{attrs:{"small":""}},[_vm._v(" check ")]):_vm._e()],1),_c('v-expand-transition',[(_vm.hasTreasurerRights && _vm.invoice.status === 'Approved')?_c('div',[_c('div',{staticClass:"mt-3 text--secondary"},[_vm._v("Überweisungsdetails:")]),_c('invoice-payment-instruction-card',{attrs:{"user-id":_vm.invoice.submitter,"usage":_vm.getUsageDescription(),"amount":_vm.parseMoneyString(_vm.invoice.amount)}}),_c('v-btn',{staticClass:"mr-4 mt-2",attrs:{"depressed":""},on:{"click":function($event){return _vm.paid()}}},[_vm._v(" Überwiesen ")])],1):_vm._e()])],1)],2):_vm._e(),(_vm.status === 'Rejected')?_c('v-timeline-item',{attrs:{"small":"","tooltip":false}},[_c('template',{slot:"icon"},[_c('invoice-status',{attrs:{"status":"Rejected"}})],1),(_vm.status === 'Rejected')?_c('div',{staticStyle:{"height":"32px","width":"45px","position":"relative","left":"-30px","top":"-4px","margin-bottom":"-36px"},style:({ 'background-color': _vm.statusToColor() })}):_vm._e(),_c('v-card',{staticClass:"mt-n1 ml-2 mx-0 pt-1 pl-2 pr-2",attrs:{"flat":"","color":_vm.status === 'Rejected' ? _vm.statusToColor() : '',"min-height":"32","width":"150"}},[_c('div',{staticClass:"font-italic",class:{
            'text--primary': 'Rejected' === _vm.status,
            'text--disabled': _vm.statusGreater('Rejected', _vm.status),
            'text--secondary': _vm.statusLess('Rejected', _vm.status)
          }},[_vm._v(" Beleg storniert "),_c('v-icon',{attrs:{"small":""}},[_vm._v(" clear ")])],1),(_vm.invoice.statusMessage)?_c('v-row',{staticClass:"justify-start mx-0 pt-1 pb-2"},[_c('v-card',{staticStyle:{"font-size":"14px"},attrs:{"color":"tertiary","flat":"","rounded":"","outlined":"","width":"360"}},[_c('div',{staticClass:"pa-1"},[_c('div',{staticClass:"font-weight-light"},[_vm._v("Status Nachricht:")]),_c('div',[_vm._v(_vm._s(_vm.invoice.statusMessage))])])])],1):_vm._e()],1)],2):_vm._e(),(_vm.status !== 'Rejected')?_c('v-timeline-item',{attrs:{"small":""}},[_c('template',{slot:"icon"},[_c('invoice-status',{attrs:{"status":"Paid","tooltip":false,"disabled":_vm.invoice.status !== 'Paid'}})],1),(_vm.status === 'Paid')?_c('div',{staticStyle:{"height":"32px","width":"45px","position":"relative","left":"-30px","top":"-4px","margin-bottom":"-36px"},style:({ 'background-color': _vm.statusToColor() })}):_vm._e(),_c('v-card',{staticClass:"mt-n1 ml-2 mx-0 pt-1 pl-2 pr-2 pb-2",attrs:{"flat":"","color":_vm.status === 'Paid' ? _vm.statusToColor() : '',"width":"292","min-height":"32"}},[_c('div',{staticClass:"font-italic mb-n2",class:{
            'text-primary': 'Paid' === _vm.status,
            'text--disabled': _vm.statusGreater('Paid', _vm.status),
            'text-secondary': _vm.statusLess('Paid', _vm.status)
          }},[_vm._v(" Beleg beglichen "),(_vm.statusLessOrEqu('Paid', _vm.status))?_c('v-icon',{attrs:{"small":""}},[_vm._v(" check ")]):_vm._e()],1),_c('v-expand-transition',[(_vm.hasTreasurerRights && _vm.invoice.status === 'Paid')?_c('div',[_c('v-btn',{staticClass:"mt-4",attrs:{"block":"","depressed":"","elevation":"0"},on:{"click":function($event){return _vm.donePhysicalReceipt()}}},[_vm._v(" Original Beleg abgelegt ")]),_c('v-btn',{staticClass:"mt-2",attrs:{"block":"","depressed":"","elevation":"0"},on:{"click":function($event){return _vm.doneDigitalReceipt()}}},[_vm._v(" Original Beleg ist digital ")]),_c('v-btn',{staticClass:"mt-2",attrs:{"block":"","depressed":"","elevation":"0"},on:{"click":function($event){return _vm.doneNoReceipt()}}},[_vm._v(" kein Beleg vorhanden ")])],1):_vm._e()])],1)],2):_vm._e(),(_vm.status !== 'Rejected')?_c('v-timeline-item',{attrs:{"small":""}},[_c('template',{slot:"icon"},[_c('invoice-status',{attrs:{"status":_vm.status.startsWith('Done') ? _vm.status : 'Done',"tooltip":false,"disabled":!_vm.status.startsWith('Done')}})],1),(_vm.status.startsWith('Done'))?_c('div',{staticStyle:{"height":"32px","width":"45px","position":"relative","left":"-30px","top":"-4px","margin-bottom":"-36px"},style:({ 'background-color': _vm.statusToColor() })}):_vm._e(),_c('v-card',{staticClass:"mt-n1 ml-2 mx-0 pt-1 pl-2 pr-2",attrs:{"flat":"","color":_vm.status.startsWith('Done') ? _vm.statusToColor() : '',"height":"32","width":"292"}},[_c('div',{staticClass:"font-italic",class:{
            'text-primary': _vm.status.startsWith('Done'),
            'text--disabled': _vm.statusGreater('Done', _vm.status),
            'text-secondary': _vm.statusLess('Done', _vm.status)
          },style:({
            'margin-left': _vm.status.startsWith('Done') ? '12px' : '0px'
          })},[_vm._v(" "+_vm._s(_vm.statusToTooltip(_vm.status))+" "),(_vm.statusLessOrEqu('Done', _vm.status))?_c('v-icon',{attrs:{"small":""}},[_vm._v(" check ")]):_vm._e()],1)])],2):_vm._e()],1),(_vm.hasTreasurerRights)?_c('v-btn',{staticClass:"ma-1",attrs:{"text":""},on:{"click":function($event){return _vm.showManuelEditorDialog()}}},[_vm._v(" Status Anpassen ")]):_vm._e(),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Beleg Stornieren ")]),_c('v-card-subtitle',{staticClass:"mt-n1"},[_vm._v(" Über eine Status Nachricht kannst du den Grund für deine Entscheidung mitteilen. ")]),_c('v-card-text',[_c('v-textarea',{attrs:{"outlined":"","rows":"2","hide-details":"","label":"Status Nachricht"},model:{value:(_vm.newStatusMessage),callback:function ($$v) {_vm.newStatusMessage=$$v},expression:"newStatusMessage"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.confirmDialog = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.reject()}}},[_vm._v(" Stornieren ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.manuelDialog),callback:function ($$v) {_vm.manuelDialog=$$v},expression:"manuelDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Manueller Status Editor ")]),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.possibleStatus,"label":"Status"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('invoice-status',{attrs:{"tooltip":false,"status":item}})]}},{key:"selection",fn:function(ref){
          var item = ref.item;
return [_c('invoice-status',{attrs:{"tooltip":false,"status":item}})]}}]),model:{value:(_vm.newStatus),callback:function ($$v) {_vm.newStatus=$$v},expression:"newStatus"}}),_c('v-textarea',{attrs:{"outlined":"","rows":"2","hide-details":"","label":"Status Nachricht"},model:{value:(_vm.newStatusMessage),callback:function ($$v) {_vm.newStatusMessage=$$v},expression:"newStatusMessage"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.manuelDialog = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.saveManuelChanges()}}},[_vm._v(" Speichern ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }