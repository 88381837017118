<template>
  <v-list>
    <div v-for="auditEvent in auditLog" :key="auditEvent.id">
      <v-list-item class="py-3">
        <v-list-item-content>
          <v-row class="mx-0 mb-1 align-center">
            <span class="mr-2 font-weight-medium">{{
              formatDateTime(auditEvent.created_at)
            }}</span>
            <span class="mr-1 font-italic">{{ auditEvent.action }}</span>
            <span class="mr-2 font-weight-light">von</span>
            <submitter-chip class="my-1" small :submitter="auditEvent.actor" />
          </v-row>
          <v-row no-gutters>
            <v-card flat color="grey lighten-4" style="width: 100%">
              <v-card-text style="white-space: pre; font-family: monospace;">
                <div>Data: {{ formatJson(auditEvent.data) }}</div>
              </v-card-text>
            </v-card>
          </v-row>
          <div
            v-if="JSON.parse(auditEvent.data).receiptCopy.length > 0"
            class="mt-3"
          >
            <div class="text-body-2 ml-1 font-weight-light">Belege:</div>
            <v-row no-gutters>
              <receipt-copy-preview-with-overlay
                v-for="(receipt, partNr) in JSON.parse(auditEvent.data)
                  .receiptCopy"
                :key="receipt.name"
                :width="100"
                :height="130"
                :file-name="receipt"
                class="ma-1"
              >
                <v-btn icon @click="openFileInNewTab(receipt.name)">
                  <v-icon>
                    open_in_new
                  </v-icon>
                </v-btn>
                <v-btn icon @click="downloadFile(receipt, partNr + 1)">
                  <v-icon>
                    get_app
                  </v-icon>
                </v-btn>
              </receipt-copy-preview-with-overlay>
            </v-row>
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
    </div>
  </v-list>
</template>

<script>
import InvoiceAPI from "@/api/InvoiceAPI";
import SubmitterChip from "@/components/invoices/SubmitterChip";
import Parser from "@/utils/Parser";
import ReceiptCopyPreviewWithOverlay from "@/components/preview/ReceiptCopyPreviewWithOverlay";

export default {
  name: "InvoiceAuditLog",
  components: { ReceiptCopyPreviewWithOverlay, SubmitterChip },
  props: {
    invoiceId: Number
  },
  data() {
    return {
      auditLog: []
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    formatDateTime(datetime) {
      return Parser.parseDateTime(new Date(datetime));
    },
    formatJson(jsonString) {
      let obj = JSON.parse(jsonString);
      return JSON.stringify(obj, null, 4);
    },
    openFileInNewTab(filename) {
      window.open(InvoiceAPI.getReceiptCopyUrl(filename), "_blank");
    },
    async downloadFile(file, partNr) {
      let a = document.createElement("a");
      a.download =
        this.invoiceId > 0
          ? "Rechnung#" + this.invoiceId + "-" + partNr
          : file.name;
      a.href = window.URL.createObjectURL(file);
      a.click();
    }
  },
  async mounted() {
    this.auditLog = await InvoiceAPI.getAuditLogOfInvoice(this.invoiceId);
  }
};
</script>

<style scoped></style>
