<template>
  <div>
    <v-tooltip bottom :disabled="!tooltip">
      <template v-slot:activator="{ on }">
        <v-chip v-on="on" :color="chipColor" :small="small">
          {{ statusToText() }}
        </v-chip>
      </template>
      <span>{{ statusToTooltip() }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import InvoiceStatusUtility from "@/utils/InvoiceStatusUtility";

export default {
  name: "InvoiceStatus",
  props: {
    status: String,
    small: Boolean,
    tooltip: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    chipColor() {
      return this.disabled ? "grey" : this.statusToColor(this.status);
    }
  },
  methods: {
    statusToText() {
      return InvoiceStatusUtility.statusToText(this.status);
    },
    statusToColor() {
      return InvoiceStatusUtility.statusToColor(this.status);
    },
    statusToTooltip() {
      return InvoiceStatusUtility.statusToTooltip(this.status);
    }
  }
};
</script>

<style scoped></style>
