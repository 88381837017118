<template>
  <v-card outlined>
    <v-chip
      style="position: absolute; top: 8px; left: 8px"
      v-if="roleDescription"
    >
      {{ roleDescription }}
    </v-chip>
    <v-row class="mx-0 mt-2 justify-center">
      <avatar :user-id="user.id" />
    </v-row>
    <div class="text-center mt-1 font-weight-medium">
      {{ user.forename + " " + user.surname }}
    </div>
    <v-list dense color="tertiary" class="py-0 mt-2">
      <v-list-item>
        <v-list-item-icon>
          <v-icon>place</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ user.street + " " + user.postalCode + " " + user.city }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>email</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <a :href="'mailto:' + user.email">
            {{ user.email }}
          </a>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>local_phone</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ parsePhones(user) }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import Parser from "@/utils/Parser";
import { mapGetters } from "vuex";
import Avatar from "@/components/user/Avatar";

export default {
  name: "UserCard",
  components: { Avatar },
  props: {
    userId: Number,
    roleDescription: String
  },
  computed: {
    ...mapGetters("users", { userGetter: "user" }),
    user() {
      return this.userGetter(this.userId);
    }
  },
  methods: {
    parsePhones(user) {
      return Parser.parseTelefonNumber(user.phone1, user.phone2, user.phone3);
    }
  }
};
</script>

<style scoped></style>
