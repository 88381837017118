<template>
  <div>
    <v-row no-gutters class="mt-2">
      <v-btn
        depressed
        height="40"
        class="ml-4 mb-5"
        @click="tagManagerDialog = true"
        v-if="isAdmin || isStavo || isTreasurer"
      >
        Tags verwalten
      </v-btn>
      <v-dialog
        tile
        v-model="tagManagerDialog"
        width="600"
        :fullscreen="isMobile"
      >
        <invoice-tag-manager>
          <template v-slot:headerRight>
            <v-btn text @click="tagManagerDialog = false">
              Schließen
            </v-btn>
          </template>
        </invoice-tag-manager>
      </v-dialog>
      <v-autocomplete
        class="ml-3 mb-3"
        style="max-width: 350px"
        outlined
        dense
        hide-details
        v-model="searchTags"
        :items="activeTags"
        chips
        label="Suche"
        multiple
        prepend-inner-icon="search"
        item-text="name"
        item-value="id"
      >
        <template v-slot:selection="data">
          <v-chip
            small
            close
            @click:close="
              searchTags.splice(searchTags.indexOf(tag => tag.id === data.id))
            "
          >
            {{ data.item.name }}
          </v-chip>
        </template>
        <template v-slot:no-data>
          <v-list-item dense>
            <v-list-item-content>
              Tag nich gefunden
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-row>
    <v-card :outlined="!isMobile" elevation="0" tile>
      <div
        v-for="tagWithInvoices in searchedTagsWithInvoices"
        :key="tagWithInvoices.tag.id"
        class="mt-3"
      >
        <v-row no-gutters class="px-4">
          <div class="text-h6">
            {{ tagWithInvoices.tag.name }}
          </div>
          <v-spacer />
          <div class="text-overline">Gesamt:</div>
          <div style="width: 100px" class="text-right text-h6 ml-3">
            {{ sumInvoices(tagWithInvoices.invoices) }} €
          </div>
        </v-row>
        <div v-if="tagWithInvoices.invoices.length === 0">
          <v-list-item-avatar>
            <v-icon>
              receipt
            </v-icon>
          </v-list-item-avatar>
          <span class="font-weight-light">Keine zugehörigen Positionen</span>
        </div>
        <v-list-group v-else no-action :value="false" class="pt-1">
          <template v-slot:activator>
            <v-list-item-avatar>
              <v-icon>
                receipt
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content class="text-body-1">
              {{ tagWithInvoices.invoices.length }} zugehörige Positionen:
            </v-list-item-content>
          </template>
          <div v-for="invoice in tagWithInvoices.invoices" :key="invoice.id">
            <invoice-list-item :invoice="invoice" show-submitter />
            <v-divider />
          </div>
        </v-list-group>
        <v-divider />
      </div>
    </v-card>
  </div>
</template>

<script>
import InvoiceTagManager from "@/components/invoices/InvoiceTagManager";
import { mapGetters, mapState } from "vuex";
import Parser from "@/utils/Parser";
import InvoiceListItem from "@/components/invoices/InvoiceListItem";
export default {
  name: "InvoiceTagTab",
  components: { InvoiceListItem, InvoiceTagManager },
  data() {
    return {
      tagManagerDialog: false,
      searchTags: []
    };
  },
  computed: {
    ...mapGetters("user", ["isAdmin", "isTreasurer", "isStavo"]),
    ...mapGetters("invoices", ["activeTags"]),
    ...mapState("invoices", ["tags", "invoices"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    tagsWithInvoices() {
      let tagsWithInvoices = [];
      this.tags.forEach(tag => {
        tagsWithInvoices.push({
          tag: tag,
          invoices: this.invoices.filter(invoice =>
            invoice.tags.includes(tag.id)
          )
        });
      });
      return tagsWithInvoices;
    },
    searchedTagsWithInvoices() {
      if (!this.searchTags.length) return this.tagsWithInvoices;
      return this.tagsWithInvoices.filter(tagWithInvoices =>
        this.searchTags.includes(tagWithInvoices.tag.id)
      );
    }
  },
  methods: {
    sumInvoices(invoices) {
      let checkInvoiceStatus = invoice => {
        return invoice.status !== "Rejected";
      };
      return this.formatMoney(
        invoices.reduce(
          (acc, invoice) =>
            acc +
            (checkInvoiceStatus(invoice)
              ? this.parseMoneyString(invoice.amount)
              : 0),
          0
        )
      );
    },
    parseMoneyString(moneyString) {
      return Parser.stringToMoney(moneyString);
    },
    formatMoney(money) {
      return Parser.moneyToString(money);
    }
  }
};
</script>

<style scoped></style>
